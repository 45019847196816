import React from 'react';

export default function HeroBanner({ data }) {
	return (
		<div className='herobanner' data-parallax='scroll' style={{
			backgroundImage: "url(" + data.background + ")",
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat'
		}}>
			<div className='container'>
				<div className='row centered'>
					<div className='col-xl-6 col-lg-9'>
						<div className="herobanner__tagline">{data.tagline}</div>
						<h1 className="herobanner__title">{data.title}</h1>
						<div className="herobanner__intro">{data.intro}</div>
					</div>
				</div>
			</div>
		</div>
	)
}