import React from 'react';
import parse from 'html-react-parser';

export default function Introduction({ data }) {
	return (
		<section className="introduction">
			<div className="container">
				<div className="row centered">
					<div className="col-lg-8">
						<div className="intro__wrap">
							{parse(data.intro)}
						</div>
						<div className="introduction__profile">
						<img className="introduction__profile-pic" src={data.profilePic} alt={data.name} />
						</div>
						{/* <div className="intro__wrap">
							{data.second}
						</div> */}
					</div>
				</div>
			</div>
		</section>
	)
}