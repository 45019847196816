import React from 'react';

export default function Social({ data }) {
	return (
		<div className="social">
			<div className="container">
				<div className="row centered">
					{data.map((data, key) => {
						return (
							<div className="col-md-2 col-sm-2 col-2" key={key}>
								<div className="social__wrap">
									<a href={data.link}><img className="social__icon" src={data.icon} alt={data.name} /></a>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	)
}