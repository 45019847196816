

import React from 'react';

export default function Footer({ data }) {
	if (data) {
		return (
			<div className="testimonial">
				<div className="testimonial__quote">
					"{data.quote}"
				</div>
				<div className="testimonial__citation">
					- {data.citation}
				</div>
			</div>
		)
	} 
}