import React from 'react';

export default function Logos({ data }) {
	if (!Array.isArray(data)) {
		return null;
	}

	return (
		<div className="techlogos">
			{data.map((tech) => {
				let imageToRender = null;
				if (tech === 'HTML') {
					imageToRender = <img src="./img/HTML.svg" alt="HTML Logo" className="techlogos__logo" />;
				} else if (tech === 'SCSS') {
					imageToRender = <img src="./img/Sass.svg" alt="SCSS Logo" className="techlogos__logo" />;
				} else if (tech === 'WordPress') {
					imageToRender = <img src="./img/Wordpress.svg" alt="WordPress Logo" className="techlogos__logo" />;
				} else if (tech === 'JavaScript') {
					imageToRender = <img src="./img/JavaScript.svg" alt="JavaScript Logo" className="techlogos__logo" />;
				} else if (tech === 'React') {
					imageToRender = <img src="./img/React.svg" alt="React Logo" className="techlogos__logo" />;
				} else if (tech === 'PHP') {
					imageToRender = <img src="./img/php.svg" alt="PHP Logo" className="techlogos__logo" />;
				}

				return <div  key={tech}>{imageToRender}</div>;
			})}
		</div>
	);
}