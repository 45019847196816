import Testimonial from '../components/testimonials';
import Slider from 'react-slick';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 6000,
	adaptiveHeight: true
};

export default function TestimonialWide({ data }) {
	return (
		<section className="testimonial-wide">
			<div className="container">
				<div className="row centered">
					<div className="col-lg-10">
						<Slider {...settings}>
							{data.map((data, key) => {
								return (
									<Testimonial data={data} key={key} />
								);
							})}
						</Slider>
					</div>
				</div>
			</div>
		</section>
	)
}