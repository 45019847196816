import React from 'react';

export default function Technologies({ data }) {
	return (
		<section className="tech">
			<div className="container">
				<div className="row centered">
					{data.map((data, key) => {
						return (
							<div className="col-md-2 col-sm-4 col-6" key={key}>
								<div className="tech__wrap">
									<img className="tech__icon" src={data.logo} alt={data.name} />
									<div className="tech__name">{data.name}</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	)
}