import React from 'react';
import Logos from '../components/techlogo';

export default function Portfolio({ data }) {
	return (
		<section className="portfolio">
			<div className="container overflow-hidden">
				<h2 className="portfolio__title">Portfolio</h2>
				<div className="portfolio__grid">
					{data.map((data, key) => {
						return (
							<div className="portfolio__card" key={key}>
								<a href={data.url} target="_blank" rel="noreferrer">
									<div className="portfolio__logo-wrap">
										<img className="portfolio__logo" src={data.logo} alt={data.name} />
									</div>
									<div className="portfolio__inner">
										<h3 className="portfolio__name">{data.name}</h3>
										<p>{data.description}</p>
										<Logos className="techlogos_logo" data={data.technologies} />
									</div>
								</a>
							</div>
						);
					})}
				</div>
			</div>
		</section >
	)
}