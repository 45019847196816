import React from 'react';
import Social from './social.js';

export default function Footer({data}) {
	return (
		<div>
			<Social data={data.social} />
			<div className="footer">
				<div className="container">
				<div className="row centered">
				<div className="col-lg-4">
					<p>{data.info}</p>
				</div>
				<div className="col-lg-4">
					<p>{data.location}</p>
				</div>
				<div className="col-lg-4">
					<p><a href={`mailto:${data.contactEmail}`}>{data.contactEmail}</a></p>
				</div>
			</div>
				</div>
			</div>
		</div>
	)
}