import data from "./content.json";
import HeroBanner from './modules/herobanner';
import Technologies from './modules/technologies';
import Introduction from './modules/introSection';
import Portfolio from './modules/portfolio';
import TestimonialWide from "./modules/testimonial";
import Footer from './modules/footer';
import QuoteOfTheDay from './modules/apiQuote';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './scss/App.scss';


function App() {
	return (
		<div className="App">
			<HeroBanner data={data.hero} />
			<Technologies data={data.technologies} />
			<Introduction data={data.introduction} />
			<Portfolio data={data.portfolio} />
			{/* <QuoteOfTheDay /> */}
			<TestimonialWide data={data.testimonial} />
			<Footer data={data.footer} />
		</div>
	);
}

export default App;
